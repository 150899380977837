<template>
    <ts-loading-banner :loading="loading">
        <div class="tw-space-y-2" v-if="resources !== undefined">
            <div class="row">
                <div class="col-md-6 tw-space-y-1">
                    <label>{{
                        $t("stockAdjustment.stockAdjustmentDate")
                    }}</label>
                    <div class="tw-border tw-rounded tw-px-3 tw-py-1">
                        {{ resources.adjustment_date }}
                    </div>
                </div>
                <div class="col-md-6 tw-space-y-1">
                    <label>{{ $t("stockAdjustment.shopName") }}</label>
                    <div class="tw-border tw-rounded tw-px-3 tw-py-1">
                        {{ resources.shop_name }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <label>{{ $t("stockAdjustment.remark") }}</label>
                    <div class="tw-border tw-rounded tw-px-3 tw-py-1 tw-h-14">
                        {{ resources.remarks }}
                    </div>
                </div>
            </div>
            <h2 class="tw-uppercase tw-text-gray-400 tw-text-sm">
                {{ $t("stockAdjustment.itemDetail") }}
            </h2>
            <div class="tw-overflow-auto">
                <div class="table-grid">
                    <div
                        class="tw-px-2 tw-py-1 tw-bg-blue-500 tw-text-white tw-uppercase tw-font-semibold tw-border-b tw-whitespace-nowrap tw-text-center"
                    >
                        {{ $t("stockAdjustment.no") }}
                    </div>
                    <div
                        class="tw-px-2 tw-py-1 tw-bg-blue-500 tw-text-white tw-uppercase tw-font-semibold tw-border-b tw-whitespace-nowrap tw-text-left"
                    >
                        {{ $t("stockAdjustment.barcode") }}
                    </div>
                    <div
                        class="tw-px-2 tw-py-1 tw-bg-blue-500 tw-text-white tw-uppercase tw-font-semibold tw-border-b tw-whitespace-nowrap tw-text-left"
                    >
                        {{ $t("stockAdjustment.productName") }}
                    </div>
                    <div
                        class="tw-px-2 tw-py-1 tw-bg-blue-500 tw-text-white tw-uppercase tw-font-semibold tw-border-b tw-whitespace-nowrap tw-text-left"
                    >
                        {{ $t("stockAdjustment.uom") }}
                    </div>
                    <div
                        class="tw-px-2 tw-py-1 tw-bg-blue-500 tw-text-white tw-uppercase tw-font-semibold tw-border-b tw-whitespace-nowrap tw-text-right"
                    >
                        {{ $t("stockAdjustment.endingBalance") }}
                    </div>
                    <div
                        class="tw-px-2 tw-py-1 tw-bg-blue-500 tw-text-white tw-uppercase tw-font-semibold tw-border-b tw-whitespace-nowrap tw-text-right"
                    >
                        <p class="tw-mb-0">
                            {{ $t("stockAdjustment.currentQty") }}
                        </p>
                    </div>
                    <div
                        class="tw-px-2 tw-py-1 tw-bg-blue-500 tw-text-white tw-uppercase tw-font-semibold tw-border-b tw-whitespace-nowrap tw-text-right"
                    >
                        {{ $t("stockAdjustment.differenceQty") }}
                    </div>
                    <div
                        class="tw-px-2 tw-py-1 tw-bg-blue-500 tw-text-white tw-uppercase tw-font-semibold tw-border-b tw-whitespace-nowrap tw-text-left"
                    >
                        {{ $t("stockAdjustment.reason") }}
                    </div>
                </div>
                <div
                    v-for="(item, index) in resources.detail"
                    :key="index"
                    class="table-grid"
                >
                    <div
                        class="tw-px-2 tw-py-1 tw-font-semibold tw-text-center"
                    >
                        {{ index + 1 }}
                    </div>
                    <div class="tw-px-2 tw-py-1 tw-font-semibold">
                        {{ item.barcode }}
                    </div>
                    <div class="tw-px-2 tw-py-1 tw-font-semibold">
                        {{ item.product_name }}
                    </div>
                    <div class="tw-px-2 tw-py-1 tw-font-semibold">
                        {{  item.uom_name_en }}
                    </div>
                    <div class="tw-px-2 tw-py-1 tw-font-semibold tw-text-right">
                        {{ item.current_qty }}
                    </div>
                    <div class="tw-px-2 tw-py-1 tw-font-semibold tw-text-right">
                        {{  item.new_qty }}
                    </div>
                    <div class="tw-px-2 tw-py-1 tw-font-semibold tw-text-right">
                        {{ item.different_qty }}
                    </div>
                    <div class="tw-px-2 tw-py-1 tw-font-semibold tw-text-right">
                        {{ item.reason }}
                    </div>
                </div>
            </div>
        </div>
        <div
            class="tw-w-full tw-h-28 tw-flex tw-items-center tw-justify-center"
            v-if="!resources"
        >
            <div>
                <p class="tw-mb-0">
                    <i class="fas fa-folder-open fa-3x tw-text-gray-300"></i>
                </p>
                <p class="tw-font-semibold tw-text-gray-300">
                    {{ $t("stockAdjustment.empty") }}
                </p>
            </div>
        </div>
    </ts-loading-banner>
</template>

<script>
import { mapActions } from "vuex";
export default {
    name: "viewStockAdjustment",
    props: ["viewAdjustment"],
    data() {
        return {
            loading: false,
            resources: undefined
        };
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        ...mapActions("inventory/stockAdjustment", ["show"]),
        fetchData() {
            this.loading = true;
            this.show(this.viewAdjustment.stock_adjustment_id)
                .then(result => {
                    this.resources = result.data;
                })
                .catch(err => {
                    this.$notify({ type: "error", text: err.message });
                })
                .finally(() => (this.loading = false));
        }
    }
};
</script>

<style>
.table-grid {
    display: grid;
    grid-template-columns: 40px 150px 250px 100px 120px 100px 100px auto;
}
</style>
