<script>
import { Errors } from "form-backend-validation";
import { uniqBy } from "lodash";
export default {
    name: "stockAdjustmentItemDetail",
    props: ["value", "validate", "inventories"],
    data() {
        return {
            errors: new Errors(),
            model: {}
        };
    },
    created() {
        this.model = this.value;
    },
    computed: {
        productList() {
            return uniqBy(
                this.inventories.map(el => ({
                    value: el.product_id,
                    label: `${el.product_name_kh} - ${el.product_name_en}`,
                    barcode: el.barcode
                })),
                "value"
            );
        },
        uomByProduct: vm => productId => {
            if (productId !== undefined) {
                return uniqBy(
                    vm.inventories
                        .filter(inventory => inventory.product_id === productId)
                        .map(el => ({
                            uom_id: el.uom_id,
                            uom_name_en: el.uom_name_en
                        })),
                    "uom_id"
                );
            }
            return [];
        }
    },
    methods: {
        onAddMoreItem() {
            this.model.sub_item_list.push({
                product_id: "",
                uom_id: "",
                current_qty: "",
                new_qty: "",
                qty_different: "",
                reason: ""
            });
        },
        removeItem(index) {
            this.model.sub_item_list.splice(index, 1);
        },
        onProductChange(row) {
            row.uom_id = "";
            row.current_qty = "";
            const uom = this.uomByProduct(row.product_id);
            if (uom.length === 1) {
                row.uom_id = uom[0].uom_id;
                const findEnding = this.inventories.find(
                    v =>
                        v.product_id === row.product_id &&
                        v.uom_id === row.uom_id
                );
                if (findEnding !== undefined) {
                    row.current_qty = findEnding.ending_balance;
                }
            }
            this.calculate(row);
        },
        onUomChange(row) {
						row.current_qty = ''
            const findEnding = this.inventories.find(
                v => v.product_id === row.product_id && v.uom_id === row.uom_id
            );
            if (findEnding !== undefined) {
                row.current_qty = findEnding.ending_balance;
            }
            this.calculate(row);
        },
        calculate(row) {
            row.qty_different = row.new_qty - row.current_qty;
        }
    },
    watch: {
        validate: function(error) {
            this.errors = new Errors(error.errors);
        }
    }
};
</script>

<template>
    <div class="tw-py-4">
        <h2 class="tw-uppercase tw-text-gray-400 tw-text-sm">
            {{ $t("stockAdjustment.itemDetail") }}
        </h2>
        <div class="tw-mt-2">
            <div class="table-grid">
                <div
                    class="tw-px-2 tw-py-1 tw-bg-blue-500 tw-text-white tw-uppercase tw-font-semibold tw-border-b tw-whitespace-nowrap tw-text-center"
                >
                    {{ $t("stockAdjustment.no") }}
                </div>
                <div
                    class="tw-px-2 tw-py-1 tw-bg-blue-500 tw-text-white tw-uppercase tw-font-semibold tw-border-b tw-whitespace-nowrap tw-text-left"
                >
                    {{ $t("stockAdjustment.barcode") }}
                </div>
                <div
                    class="tw-px-2 tw-py-1 tw-bg-blue-500 tw-text-white tw-uppercase tw-font-semibold tw-border-b tw-whitespace-nowrap tw-text-left"
                >
                    {{ $t("stockAdjustment.productName") }}
                </div>
                <div
                    class="tw-px-2 tw-py-1 tw-bg-blue-500 tw-text-white tw-uppercase tw-font-semibold tw-border-b tw-whitespace-nowrap tw-text-left"
                >
                    {{ $t("stockAdjustment.uom") }}
                </div>
                <div
                    class="tw-px-2 tw-py-1 tw-bg-blue-500 tw-text-white tw-uppercase tw-font-semibold tw-border-b tw-whitespace-nowrap tw-text-right"
                >
                    {{ $t("stockAdjustment.endingBalance") }}
                </div>
                <div
                    class="tw-px-2 tw-py-1 tw-bg-blue-500 tw-text-white tw-uppercase tw-font-semibold tw-border-b tw-whitespace-nowrap tw-text-right"
                >
                    <p class="required tw-mb-0">
                        {{ $t("stockAdjustment.currentQty") }}
                    </p>
                </div>
                <div
                    class="tw-px-2 tw-py-1 tw-bg-blue-500 tw-text-white tw-uppercase tw-font-semibold tw-border-b tw-whitespace-nowrap tw-text-right"
                >
                    {{ $t("stockAdjustment.differenceQty") }}
                </div>
                <div
                    class="tw-px-2 tw-py-1 tw-bg-blue-500 tw-text-white tw-uppercase tw-font-semibold tw-border-b tw-whitespace-nowrap tw-text-left"
                >
                    {{ $t("stockAdjustment.reason") }}
                </div>
                <div
                    class="tw-px-2 tw-py-1 tw-bg-blue-500 tw-text-white tw-uppercase tw-font-semibold tw-border-b tw-whitespace-nowrap tw-text-center"
                >
                    <a
                        v-if="model.shop_id"
                        href="#"
                        class="tw-text-white"
                        @click.prevent="onAddMoreItem"
                    >
                        <i class="fas fa-plus"></i>
                    </a>
                </div>
            </div>
            <!-- body -->
            <div
                v-for="(item, index) in model.sub_item_list"
                :key="index"
                class="table-grid"
            >
                <div class="tw-px-2 tw-py-1 tw-font-semibold tw-text-center">
                    {{ index + 1 }}
                </div>
                <div class="tw-px-2 tw-py-1 tw-font-semibold">
                    <select
                        v-model="item.product_id"
                        class="tw-outline-none tw-px-2"
                        @change="onProductChange(item)"
                    >
                        <option value="">--select--</option>
                        <option
                            v-for="(p, index) in productList"
                            :key="index"
                            :value="p.value"
                            >{{ p.barcode }}</option
                        >
                    </select>
										<p
											v-if="errors.has(`sub_item_list.${index}.product_id`)"
											class="tw-text-red-500 tw-mb-0"
										>{{  errors.first(`sub_item_list.${index}.product_id`) }}</p>
                </div>
                <div class="tw-px-2 tw-py-1 tw-font-semibold">
                    <select
                        v-model="item.product_id"
                        class="tw-outline-none tw-px-2"
                        @change="onProductChange(item)"
                    >
                        <option value="">--select--</option>
                        <option
                            v-for="(p, index) in productList"
                            :key="index"
                            :value="p.value"
                            >{{ p.label }}</option
                        >
                    </select>
										<p
											v-if="errors.has(`sub_item_list.${index}.product_id`)"
											class="tw-text-red-500 tw-mb-0"
										>{{  errors.first(`sub_item_list.${index}.product_id`) }}</p>										
                </div>
                <div class="tw-px-2 tw-py-1 tw-font-semibold">
                    <select
                        class="tw-outline-none tw-w-full tw-px-2"
                        v-model="item.uom_id"
												@change="onUomChange(item)"
                    >
                        <option value="">-select-</option>
                        <option
                            v-for="(u, index) in uomByProduct(item.product_id)"
                            :key="index"
                            :value="u.uom_id"
                            >{{ u.uom_name_en }}</option
                        >
                    </select>
										<p
											v-if="errors.has(`sub_item_list.${index}.uom_id`)"
											class="tw-text-red-500 tw-mb-0"
										>{{  errors.first(`sub_item_list.${index}.uom_id`) }}</p>										
                </div>
                <div class="tw-px-2 tw-py-1 tw-font-semibold tw-text-right">
                    {{ item.current_qty }}
                </div>
                <div class="tw-px-2 tw-py-1 tw-font-semibold tw-text-right">
                    <input
                        v-model.number="item.new_qty"
                        class="tw-outline-none tw-w-full tw-text-right"
                        :placeholder="$t('stockAdjustment.input')"
                        type="number"
                        step="any"
                        @input="calculate(item)"
                    />
										<p
											v-if="errors.has(`sub_item_list.${index}.new_qty`)"
											class="tw-text-red-500 tw-mb-0"
										>{{  errors.first(`sub_item_list.${index}.new_qty`) }}</p>										
                </div>
                <div class="tw-px-2 tw-py-1 tw-font-semibold tw-text-right">
                    {{ item.qty_different }}
                </div>
                <div class="tw-px-2 tw-py-1 tw-font-semibold tw-text-right">
                    <input
                        v-model.trim="item.reason"
                        class="tw-outline-none tw-w-full tw-text-left"
                        :placeholder="$t('stockAdjustment.input')"
                        type="text"
                    />
                </div>
                <div class="tw-px-2 tw-py-1 tw-font-semibold tw-text-center">
                    <a href="#" @click.prevent="removeItem(index)">
                        <i class="fa fa-trash text-red"></i>
                    </a>
                </div>
            </div>
            <div
                class="tw-w-full tw-h-28 tw-flex tw-items-center tw-justify-center"
                v-if="!model.sub_item_list.length"
            >
                <div>
                    <p class="tw-mb-0">
                        <i
                            class="fas fa-folder-open fa-3x tw-text-gray-300"
                        ></i>
                    </p>
                    <p class="tw-font-semibold tw-text-gray-300">
                        {{ $t("stockAdjustment.empty") }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.table-grid {
    display: grid;
    grid-template-columns: 40px 150px 250px 100px 120px 100px 100px auto 40px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
}
</style>
