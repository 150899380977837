<script>
import { mapState } from "vuex";
import { Errors } from "form-backend-validation";
import { ReasonDelete, ViewStockadjustment } from "./components";

export default {
    name: "StockAdjustment",
		components: {
			ViewStockadjustment
		},
    data() {
        return {
            loading: false,
            errors: new Errors(),
						viewAdjustment: undefined,
						showFormView: false,
            model: {
                deleted_reason: ""
            }
        };
    },
    computed: {
        ...mapState("inventory/stockAdjustment", ["resources", "pagination"]),
        search: {
            get() {
                return this.$store.state.inventory.stockAdjustment.search;
            },
            set(newValue) {
                this.$store.commit(
                    "inventory/stockAdjustment/SET_SEARCH",
                    newValue
                );
                this.$store.commit(
                    "inventory/stockAdjustment/RESET_CURRENT_PAGE"
                );
            }
        },
        dateRange: {
            get() {
                return this.$store.state.inventory.stockAdjustment.dateRange;
            },
            set(newValue) {
                this.$store.commit(
                    "inventory/stockAdjustment/SET_DATE_RANGE",
                    newValue
                );
            }
        },
        columns() {
            return [
                {
                    name: this.$t("stockAdjustment.stockAdjustmentDate"),
                    style: "width:180px"
                },
                {
                    name: this.$t("stockAdjustment.shopName"),
                    style: "width:250px"
                },
                { name: this.$t("stockAdjustment.remark") },
                {
                    name: this.$t("stockAdjustment.createdBy"),
                    style: "width:180px"
                },
                {
                    name: this.$t("actions"),
                    style: "text-align:center; width:80px"
                }
            ];
        }
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        addNew() {
            this.$router.push({ name: "stock-adjustment-create" });
        },
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("inventory/stockAdjustment/fetch", { ...attributes })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$notify({ type: "error", text: error.message });
                });
        },
        onCancelModal() {

          this.showFormView = false
          this.ViewStockadjustment = undefined  
        },
        deleteRecord(record) {
            this.model.deleted_reason = null;
            this.$confirm({
                title: this.$t("are_you_sure_to_delete"),
                content: h =>
                    h(ReasonDelete, {
                        props: {
                            value: this.model,
                            validate: this.errors,
                            placeholder: this.$t("stockAdjustment.leaveReason")
                        }
                    }),
                okText: this.$t("yes"),
                okType: "danger",
                cancelText: this.$t("no"),
                onOk: () => {
                    return this.$store
                        .dispatch("inventory/stockAdjustment/destroy", {
                            id: record.stock_adjustment_id,
                            ...this.model
                        })
                        .then(response => {
                            this.$notify({
                                type: "success",
                                text: response.message
                            });
                            this.fetchData();
                            return Promise.resolve(response);
                        })
                        .catch(error => {
                            this.$notify({
                                type: "error",
                                text: error.message
                            });
                            this.errors = new Errors(error.errors);
                            return Promise.reject(error);
                        });
                },
                onCancel() {}
            });
        },
				view(record) {
					this.viewAdjustment = record
					this.showFormView = true
				}
    }
};
</script>

<template>
    <div>
        <ts-page-title
            :title="$t('stockAdjustment.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('stockAdjustment.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="d-flex justify-content-between">
                    <div class="tw-flex tw-items-center tw-space-x-3">
                        <ts-button
                            v-if="$can('create-product-profiles')"
                            outline
                            color="info"
                            @click.prevent="addNew"
                            >{{ $t("addNew") }}
                        </ts-button>
                        <a-range-picker
                            v-model="dateRange"
                            :allowClear="false"
                            format="DD-MM-YYYY"
                            valueFormat="DD-MM-YYYY"
                            style="width: 200px"
                            @change="fetchData()"
                        />
                    </div>
                    <div class="tw-flex tw-items-center tw-space-x-3">
                        <a-input
                            v-model="search"
                            v-tooltip="$t('stockAdjustment.search')"
                            :placeholder="$t('stockAdjustment.search')"
                            style="width: 250px"
                            class="inline-block"
                        />
                    </div>
                </div>
                <div class="tw-overflow-auto tw-mt-3">
                    <ts-table
                        :columns="columns"
                        :records="resources"
                        :loading="loading"
                    >
                        <template v-slot="{ record }">
                            <td class="tw-text-left">
                                {{ record.adjustment_date }}
                            </td>
                            <td class="tw-text-left">{{ record.shop_name }}</td>
                            <td class="tw-text-left">{{ record.remarks }}</td>
                            <td class="tw-text-left">{{ record.user_name }}</td>
                            <td class="text-center tw-space-x-3">
                                <a
                                    href="#"
                                    v-tooltip="$t('view')"
                                    class="text-primary"
                                    @click.prevent="view(record)"
                                >
                                    <i class="fas fa-eye"></i>
                                </a>
                                <!-- <a
                                    href="#"
                                    v-tooltip="$t('edit')"
                                    class="text-primary"
                                    @click.prevent="edit(record)"
                                >
                                    <i class="fas fa-edit"></i>
                                </a> -->
                                <a
                                    href="#"
                                    :disabled="record._deleting"
                                    @click.prevent="deleteRecord(record)"
                                >
                                    <i
                                        class="fas fa-circle-notch spin"
                                        v-if="record._deleting"
                                    ></i>
                                    <i class="fa fa-trash text-red" v-else></i>
                                </a>
                            </td>
                        </template>
                    </ts-table>
                </div>
                <div class="d-flex justify-content-end tw-p-4">
                    <ts-pagination v-model="pagination" @navigate="fetchData">
                    </ts-pagination>
                </div>
            </ts-panel-wrapper>
        </ts-panel>

				<a-modal 
					v-if="showFormView"
					v-model="showFormView"
					:title="$t('stockAdjustment.pageTitle')"
					:footer="null"
					width="70%"
					:zIndex="1024"
				>
				<ViewStockadjustment :viewAdjustment="viewAdjustment" @cancel="onCancelModal" />
			</a-modal>
    </div>
</template>
